@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  overflow-x: hidden;
  background-color: black;
}

#root{
  overflow-x: hidden;
}

.font-grotesk{
  font-family: 'Space Grotesk', sans-serif !important;
}

.mainCont{
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.customBG{
  background: linear-gradient(81deg, rgba(73, 75, 245, 0.20) 0%, rgba(0, 0, 0, 0.00) 44.97%), #000;
}

.customBG2{
  background: linear-gradient(296deg, rgba(214, 36, 237, 0.20) 0%, rgba(0, 0, 0, 0.00) 62.79%), #000;
}

.customBG3{
  background: linear-gradient(296deg, rgba(214, 36, 237, 0.20) 0%, rgba(0, 0, 0, 0.00) 62.79%), #000;
}

.customBG4{
  background: linear-gradient(74deg, rgba(36, 217, 6, 0.20) 0.01%, rgba(0, 0, 0, 0.00) 38.27%), #000;
}

.customBG5{
  background: var(--Linear, linear-gradient(111deg, #4A25A7 -2.4%, rgba(41, 44, 236, 0.00) 100.98%));
}

.customShadow{
  box-shadow: 92px 79px 58px 0px rgba(71, 71, 88, 0.25);
}

.swiper{
  overflow: visible !important;
}

.swiper-pagination{
  max-width: fit-content;
  margin: 30px auto 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
  gap: 15px;
  z-index: 50;
}
.swiper-pagination-bullet{
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #1C1C1C;
}

.swiper-pagination-bullet-active{
  background-color: #24D906;
}


select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}